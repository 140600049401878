<template>
    <div class="wrap">
        <div class="topBox">

            <div class="topTotal">销售金额：<br /> <CountUp :end="totalInfo.mustPay" :duration="0" ref="count" v-font="24" />元</div>
            <div class="topTotal topTotal2" style="margin-left: 20px">销售数量：<br /> <CountUp :end="totalInfo.totalOrder" :duration="0" ref="count" v-font="24" />笔</div>
            <div class="queryBox">
                <DatePicker :value="dateRange" @on-change="dateRange = $event" format="yyyy-MM-dd" type="daterange" placement="bottom-end" placeholder="订单开始结束时间" style="width: 300px" />
                <Input v-model="queryParams.deskCode" placeholder="请输入订单号" style="width: 200px;margin-left: 10px" />
                <Button type="primary" style="margin-left: 10px" @click="handleQuery(index)">查询</Button>
            </div>
        </div>
        
        <Table :columns="columns" :data="list" style="margin-top: 13px;" border>
            <template #orderCode="{ row }">
                <span>{{ countOrderCode(row.orderCode) }}</span>
            </template>
            <template #totalPrice="{ row }">
                <span>{{ row.totalPrice }}元</span>
            </template>
            <template #deskCode="{ row }">
                <span>{{ row.deskCode }}号</span>
            </template>
            <template #peopleNum="{ row }">
                <span>{{ row.peopleNum }}人</span>
            </template>
            <template #yuangong>
                <span>空</span>
            </template>
            <template #kefu>
                <span>空</span>
            </template>
            <template #status>
                <span>已结单</span>
            </template>
            <template #action>
                <!-- <Button type="link" size="small" ghost>查看</Button> -->
                <Icon type="md-funnel" color="#19be6b" />
            </template>
        </Table>
        <Page :total="total" @on-change="onChange" @on-page-size-change="onSizeChange" show-sizer show-total style="margin: 12px 0 0;display: flex; justify-content: flex-start;" />
    </div>
</template>

<script>
export default {
  name: 'Demo',
  data() {
    return {
        queryParams: {
        pageNum: 1,
        pageSize: 10,
        deskCode: undefined,
        orderCode: undefined,
        status: undefined
      },
      // 表单参数
      form: {},
      totalInfo: {
          mustPay: 0,
          totalOrder: 0
      },
      dateRange: [],
      columns: [
          {
            title: '序号',
            type: 'index',
            width: 70,
            align: 'center'
        },
        {
            title: '订单ID',
            slot: 'orderCode',
            align: 'center'
        },
        {
            title: '订单时间',
            key: 'createTime',
            align: 'center'
        },
        {
            title: '订单金额',
            slot: 'totalPrice',
            align: 'center',
            width: 100
        },
        {
            title: '订单客源',
            slot: 'kefu',
            align: 'center',
            width: 100
        },
        {
            title: '订单位置号',
            slot: 'deskCode',
            align: 'center',
            width: 110
        },
        {
            title: '订单人数',
            slot: 'peopleNum',
            align: 'center',
            width: 100
        },
        {
            title: '订单服务',
            slot: 'yuangong',
            align: 'center',
            width: 110
        },
        {
            title: '订单状态',
            slot: 'status',
            align: 'center',
            width: 110,
        },
        {
            title: '操作',
            slot: 'action',
            width: 100,
            align: 'center'
        }
    ],
    total: 0,
    list: []
    }
  },
  mounted() {
		window.addEventListener('message', e => {
			if (e.data.msgType == 'totalInfo') {
        this.totalInfo = e.data.data
      } else if (e.data.msgType == 'listInfo') {
        this.list = e.data.data.list
        this.total = e.data.data.total
      }
		})
	},
  methods: {
      onChange(val) {
        this.queryParams.pageNum = val
        this.getList()
      },
      onSizeChange(val) {
        this.queryParams.pageSize = val
        if (this.queryParams.pageNum == 1) {
            this.getList()
        }
      },
    countOrderCode(val) {
        const arr = val ? val.split('-') : []
        return `${arr[0] || 0}-${arr[1] || 0}-${arr[2] || 0}${arr[3] || 0}${arr[4] || 0}`
    },
    /** 查询岗位列表 */
    getList() {
      const params = {
        ...this.queryParams
      }
      let arr = []
      if (this.dateRange && this.dateRange[0] && this.dateRange[1]) {
        arr = [`${this.dateRange[0]} 00:00:00`, `${this.dateRange[1]} 23:59:59`]
      }
      if (arr[0]) {
        params.startTime = arr[0]
      }
      if (arr[1]) {
        params.endTime = arr[1]
      }
      window.parent.postMessage({
          type: "getList",
          data: Object.keys(params).reduce((obj, item) => {
            if (params[item]) {
              obj[item] = params[item]
            }
            return obj
          }, {})
      }, "*");
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      const params = {
        ...this.queryParams
      }
      let arr = []
      if (this.dateRange && this.dateRange[0] && this.dateRange[1]) {
        arr = [`${this.dateRange[0]} 00:00:00`, `${this.dateRange[1]} 23:59:59`]
      }
      if (arr[0]) {
        params.startTime = arr[0]
      }
      if (arr[1]) {
        params.endTime = arr[1]
      }
      window.parent.postMessage({
        type: "getTotal",
        data: {
            ...params
        }
      }, "*");
      this.getList()
    },
  }
}
</script>

<style lang="less" scoped>
.wrap{
  min-height: calc(100vh - 170px);
}
.topBox{
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #515a6e;
}
.queryBox{
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  flex-wrap: wrap;
}
.topTotal{
  flex-shrink: 0;
  .ivu-count-up{
    color: #F5AC0C;
    margin-right: 4px;
  }
}
.topTotal2{
  .ivu-count-up{
    color: #19be6b;
  }
}
</style>
