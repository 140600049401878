<template>
    <div class="layout">
        <Layout :style="{minHeight: '100vh'}">
            <Sider collapsible :collapsed-width="78" v-model="isCollapsed">
                <Menu active-name="5-1" :open-names="['5']" theme="dark" width="auto" :class="menuitemClasses">
                    <MenuItem name="-1">
                        <Icon type="md-home" />
                        控制台
                    </MenuItem>
                    <Submenu name="1">
                        <template #title>
                            <Icon type="md-person" />
                            客源管理
                        </template>
                    </Submenu>
                    <Submenu name="2">
                        <template #title>
                            <Icon type="md-people" />
                            人员管理
                        </template>
                    </Submenu>
                    <Submenu name="3">
                        <template #title>
                            <Icon type="ios-paper" />
                            菜单配置
                        </template>
                    </Submenu>
                    <Submenu name="4">
                        <template #title>
                            <Icon type="ios-keypad" />
                            餐桌配置
                        </template>
                    </Submenu>
                    <Submenu name="5">
                        <template #title>
                            <Icon type="ios-list-box" />
                            订单管理
                        </template>
                        <MenuItem name="5-1">订单记录</MenuItem>
                        <MenuItem name="5-2">订单统计</MenuItem>
                        <MenuItem name="5-3">趋势分析</MenuItem>
                    </Submenu>
                    <Submenu name="6">
                        <template #title>
                            <Icon type="ios-hammer" />
                            系统配置
                        </template>
                    </Submenu>
                </Menu>
            </Sider>
            <Layout>
                <Header class="topHeader" :style="{background: '#fff', boxShadow: '0 2px 3px 2px rgba(0,0,0,.1)'}">
                    <span class="topName">{{  userData.name  }}</span>
                    <div @click="back" class="rightBox">
                        <Icon type="md-person" />
                        管理员
                        <Icon type="md-arrow-dropdown" />
                    </div>
                </Header>
                <Content :style="{padding: '0 16px 16px'}">
                    <Breadcrumb :style="{margin: '16px 0'}">
                        <BreadcrumbItem>订单管理</BreadcrumbItem>
                        <BreadcrumbItem>订单记录</BreadcrumbItem>
                    </Breadcrumb>
                    <Card> 
                        <Demo />
                    </Card>
                </Content>
            </Layout>
        </Layout>
    </div>
</template>
<script>
import Demo from '@/components/Demo'
export default {
    components: {
        Demo
    },
    data() {
        return {
            userData: {
                name: '小动物的味道我的胃'
            }
        }
    },
    mounted() {
        window.addEventListener('message', e => {
        if (e.data.msgType == 'userInfo') {
            this.userData = e.data.info
        }
        })
    },
    methods: {
        back() {
            window.parent.postMessage({
				type: 'LogOut'
			}, "*");
        }
    },
    beforeDestroy() {
        window.removeEventListener('message')
    },
}
</script>
<style>
.layout-con{
    height: 100%;
    width: 100%;
}
.menu-item span{
    display: inline-block;
    overflow: hidden;
    width: 69px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
    transition: width .2s ease .2s;
}
.menu-item i{
    transform: translateX(0px);
    transition: font-size .2s ease, transform .2s ease;
    vertical-align: middle;
    font-size: 16px;
}
.collapsed-menu span{
    width: 0px;
    transition: width .2s ease;
}
.collapsed-menu i{
    transform: translateX(5px);
    transition: font-size .2s ease .2s, transform .2s ease .2s;
    vertical-align: middle;
    font-size: 22px;
}
.dev-run-preview .dev-run-preview-edit{ display: none }
.topHeader{
    display: flex;
    align-items: center;
}
.topName{
    font-size: 20px;
    font-weight: bold;
}
.rightBox{
    margin-left: auto;
    font-size: 16px;
}
</style>